import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Row,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import { Formik, Form, FieldArray, getIn, ErrorMessage, Field } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
// actions
import { UpdatedPhone } from "../../../../store/Profile/action";
import {
  mobileNumberUpdateApiCall,
  generateOtp,
  verifyOtp,
  resendOtp,
  modMobileCheck,
  modMailSend,
  modUpdateIscompleteSend,
  modification_profile,
  generateOtpNew,
  verifyOtpNew,
  sendMobile_new,
  mobileNumberUpdate_new
} from "../../../../helpers/fakebackend_helper";
import loaderImage from "../../../../assets/images/loader-icon.png";
import axios from "axios";

const LinkNewMobile = (props) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const [generateOtpSuccess, setGenerateSuccessOtp] = useState(false);
  const [generateOtpSuccess1, setGenerateSuccessOtp1] = useState(false);
  const [mobileOtp, setMobileOtp] = useState("");
  const [showError, setError] = useState("");
  const [disableButton, setDisableButton] = useState(0);
  const dispatch = useDispatch();
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const validationSchema = Yup.object().shape({
    pan_no: Yup.string().required("This field is required"),
    old_mobile_number: Yup.string().required("This field is required"),
    new_mobile_number: Yup.string()
    // .matches(phoneRegExp, "Enter Valid Mobile Number")
    // .min(10, "Mobile number must have 10 digits.")
    // .max(10, "Mobile number not more then 10 digits.")
    // .required("This field is required"),
  });
  const client_code = localStorage.getItem("client_id");

  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails,
  }));
  ////console.log(profileDetails);
  const initialValues = {
    pan_no: !isEmpty(profileDetails.userProfile[0])
      ? profileDetails.userProfile[0].PANGIR.trimEnd()
      : "",
    old_mobile_number: !isEmpty(profileDetails.userProfile[0])
      ? +profileDetails.userProfile[0].MOBILE
      : "",
    new_mobile_number: "",
  };
  ////console.log(initialValues);
  const [formValues, setFormValues] = useState(initialValues);
  const [formValues2, setFormValues2] = useState({});
  const [counter, setCounter] = useState(30);

  console.log(props, 'propspropspropsprops');
  const handleNumberChange = (e) => {

    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      e.setState({ value: e.target.value })
    }
  };

  useEffect(() => {
    generateOtpSuccess1 === true &&
      counter > 0 &&
      setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setGenerateSuccessOtp1(false);
    }
  }, [counter, generateOtpSuccess1]);

  function onSubmitHandler(values) {
    if (values.new_mobile_number.length <= 0) {
      toast.error("Enter New Mobile Number", {
        autoClose: 1500,
      });
      return false;
    }
    if (values.new_mobile_number.length != 10) {
      toast.error("Mobile number must have 10 digits.", {
        autoClose: 1500,
      });
      return false;
    }
    setDisableButton(1);
    axios.get('https://apinew.bigul.app:4040/api/v1/validateMobile/' + values.new_mobile_number)
      .then(response => {
        if (response?.status == false && response?.message == 'Mobile no does not exist') {
          const otpValuesNew = {
            client_code: client_code,
            mobile: values.new_mobile_number,
          };
          setFormValues2(otpValuesNew);

        
          const mailcheck = {
            mobile: values.new_mobile_number,
            client_code: client_code,
          };

          sendMobile_new(mailcheck)
            .then((response) => {
              if (response.status === true) {
                setDisableButton(0);
                setGenerateSuccessOtp(true);
                setGenerateSuccessOtp1(true);
                setCounter(30);
                toast.success("OTP has been sent to your new mobile number..", {
                  autoClose: 1500,
                });
              } else {
                setDisableButton(0);
                toast.error("Couldn't send OTP. Try again!", {
                  autoClose: 1500,
                });
              }

            }).catch(() => {
              setDisableButton(0);
              toast.error("Couldn't send OTP. Try again!", {
                autoClose: 1500,
              });
            })
        }
        else {
          toast.error('Mobile number already exists !')
          setDisableButton(0);
        }
      })
      .catch(error => {
        setDisableButton(0);
        toast.error('something went wrong')
      });


  }

  const modalMobile = {
    CUpdate: false,
    otp1: false,
    vNumber1: false,
    otp2: false,
    vNumber2: false,
    done: true,
  };
  function tog_signUpModals() {
    // //console.log('submit')
    props.tog(false);
    //setmodal_signUpModals(!modal_signUpModals);
  }
  const onClickHandler = (e) => {
    e.preventDefault();
    props.sendData(modalMobile);
  };
  const submitHandler = () => {
    ////console.log("submit");
  };
  return (
    <React.Fragment>
      <ModalHeader
        className="px-4 pt-4"
        toggle={() => {
          tog_signUpModals();
        }}
      >
        <h4 className="text-2 text-color7 spacing-remove-bottom">
         Mobile Number Modification
        </h4>
      </ModalHeader>
      <ModalBody className="p-4">
        <Formik
          initialValues={{
            pan_no: formValues.pan_no.trimEnd(),
            old_mobile_number: formValues.old_mobile_number,
            new_mobile_number: formValues.new_mobile_number,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            // //console.log(values);
            onSubmitHandler(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            isSubmitting,
          }) => {
            ////console.log(values);
            return (
              <Form
                autoComplete="off"
                id="my-form"
                className="flex flex-col justify-center w-full "
                style={{ flexDirection: "column" }}
              >
                {/* <Row className="mb-3">
                    <Col lg={4}>
                      <Label
                        htmlFor="pan_no"
                        className="py-2 m-0 text-16 text-color5"
                      >
                        PAN Number :
                      </Label>
                    </Col>
                    <Col lg={7}>
                      <Input
                        name="pan_no"
                        type="text"
                        className="nominee-form-input text-color1 text-4"
                        id="pan_no"
                        placeholder="PAN Number"
                        disabled
                        value={values.pan_no}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.pan_no && touched.pan_no}
                        // helperText={touched.pan_no && errors.pan_no}
                      />
                      {errors.pan_no && (
                        <label
                          className="mt-2 mx-4"
                          style={{ color: "#f44336" }}
                        >
                          <h6 className="fs-10 text-danger">{errors.pan_no}</h6>
                        </label>
                      )}
                    </Col>
                  </Row> */}
                <Row className="mb-3">
                  <Col lg={4}>
                    <Label
                      htmlFor="old_mobile_number"
                      className="py-2 m-0 text-16 text-color5"
                    >
                      Old Mobile Number :
                    </Label>
                  </Col>
                  <Col lg={7}>
                    <Input
                      name="old_mobile_number"
                      type="number"
                      className="nominee-form-input text-color1 text-4"
                      id="old_mobile_number"
                      max={10}
                      placeholder="Old Mobile Number"
                      value={values.old_mobile_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                      error={
                        errors.old_mobile_number && touched.old_mobile_number
                      }
                    // helperText={
                    //   touched.old_mobile_number && errors.old_mobile_number
                    // }
                    />
                    {errors.old_mobile_number && (
                      <label className="mt-2 mx-4" style={{ color: "#f44336" }}>
                        <h6 className="fs-10 text-danger">
                          {errors.old_mobile_number}
                        </h6>
                      </label>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={4}>
                    <Label
                      htmlFor="new_mobile_number"
                      className="py-2 m-0 text-16 text-color5"
                    >
                      New Mobile Number :
                    </Label>
                  </Col>
                  <Col lg={7}>
                    <Input
                      name="new_mobile_number"
                      type="text"
                      className="nominee-form-input text-color1 text-4"
                      id="new_mobile_number"
                      placeholder="New Mobile Number"
                      disabled={generateOtpSuccess === true}
                      value={values.new_mobile_number}
                      onChange={(e) => {
                        const re = /^[6-9][0-9]*$/;
                        const newMobileNumber = e.target.value;
                        // Check if the new mobile number is equal to the old mobile number
                        const isSameAsOldMobile = newMobileNumber == values.old_mobile_number;
                        setFieldValue('isSameAsOldMobile', isSameAsOldMobile);
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setFieldValue('new_mobile_number', e.target.value)
                        }
                      }}
                      onBlur={handleBlur}
                      maxLength={10}
                      error={
                        errors.new_mobile_number && touched.new_mobile_number
                      }
                    // helperText={
                    //   touched.new_mobile_number && errors.new_mobile_number
                    // }
                    />
                    {/* {(
                      <label className="mt-2 mx-4" >
                        <h6 className="fs-10">You will receive OTP on your new mobile number.</h6>
                      </label>
                    )} */}
                    {values.isSameAsOldMobile && (
                      <label className="mt-2 mx-4" style={{ color: "#f44336" }}>
                        <h6 className="fs-10 text-danger">New mobile cannot be same as old mobile</h6>
                      </label>
                    )}
                    {errors.new_mobile_number && (
                      <label className="mt-2 mx-4" style={{ color: "#f44336" }}>
                        <h6 className="fs-10 text-danger">
                          {errors.new_mobile_number}
                        </h6>
                      </label>
                    )}
                  </Col>
                  <div>
                  <h4 className="text-11 text-color8 text-center mt-3">
                  You will receive OTP on your new mobile number.
                  </h4>
                </div>
                </Row>

                {/* <div className="text-center">
                    <h4 className="text-11 text-color8">
                      Updating phone number will take up to 2 hours to reflect{" "}
                      <br />
                      (owing to updation on exchanges and depositories)
                    </h4>
                  </div> */}
                {!values.isSameAsOldMobile && !errors.new_mobile_number ?
                  generateOtpSuccess === false && (
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary modal-content-Button mt-2"
                        disabled={disableButton == 0 ? false : true}
                      //onClick={(e) => onClickHandler(e)}
                      >
                        {disableButton == 0 ? 'Send OTP' : <img src={loaderImage} className="custom-loader" style={{ height: "20px", marginRight: "5px" }} />}

                      </button>
                    </div>
                  ) :
                  generateOtpSuccess === false && (
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary modal-content-Button mt-2"
                        disabled
                      //onClick={(e) => onClickHandler(e)}
                      >
                        Confirm with OTP
                      </button>
                    </div>
                  )
                }

                {generateOtpSuccess === true && (
                  <Row className="mt-4">
                    <Col lg={11}>
                      {/* <form> */}
                      <div className="input-group otp-box-input justify-content-center">
                        <Input
                          type="text"
                          className="form-control modal-input-element otp-box"
                          placeholder="Enter 6 Digit OTP"
                          name="mobile_otp"
                          // onChange={(e) => {
                          //   // //console.log(e.target.value);
                          //   e.preventDefault();

                          //   setMobileOtp(e.target.value);
                          // }}

                          maxLength={6}
                          onChange={(e) => {
                            setError('');
                            const re = /^[0-9]*$/;
                            if (e.target.value === '' || re.test(e.target.value)) {
                              if (e.target.value.length <= 6) { // Ensure the max length is not exceeded
                                setMobileOtp(e.target.value);
                                setFieldValue('mobile_otp', e.target.value)
                                setError('');
                              }
                            } else {
                              setError('Only digits are allowed');
                              // setMobileOtp('');
                            }
                            // if (e.target.value === '' || re.test(e.target.value)) {
                            //   ////console.log(e.target.value)
                            //   setFieldValue('mobile_otp', e.target.value)
                            //   setMobileOtp(e.target.value);
                            // } else {
                            //   setMobileOtp("")
                            // }
                          }}
                        />
                        <button
                          className="btn btn-primary modal-input-button"
                          type="button"
                          disabled={disableButton == 0 ? false : true}
                          onClick={(e) => {
                            setError('')

                            if (mobileOtp.length <= 0) {
                              setError("Please enter OTP.");
                              return false;
                            }
                            if (mobileOtp.length < 6) {
                              setError("Please enter correct OTP.");
                              return false;
                            }

                            const newValue = {
                              ...formValues2,
                              client_code: client_code,
                              mobile: formValues2.mobile,
                              otp: mobileOtp,
                            };
                            ////console.log(formValues2);

                            const newVal2 = {
                              client_code: client_code,
                              entry_type: 'mobile',
                              old: JSON.stringify({
                                mobile: (profileDetails.userProfile[0].MOBILE),
                              }),
                              new: JSON.stringify({
                                mobile: values.new_mobile_number,
                              }),
                              modify_key: JSON.stringify(['mobile'])
                            };
                            setDisableButton(1);
                            try {
                              // modification_profile(client_code)
                              //   .then((response) => {

                                  verifyOtpNew(newValue)
                                    .then((response) => {
                                   
                                      if (!response.status) {
                                        setDisableButton(0);
                                        setError(response.message);
                                      }
                                      if (
                                        response.status === true &&
                                        response.message ===
                                        "OTP Successfully Verified !"
                                      ) {

                                        mobileNumberUpdate_new(newVal2)
                                          .then((response) => {
                                           
                                            if (response.status == true) {
                                              setDisableButton(0);
                                              props.tog(false);
                                              props.onStateChange(1,props.name);
                                              // toast.success("Your mobile updated.", {
                                              //   autoClose: 1500,
                                              // });

                                            }
                                            else {
                                              
                                              setDisableButton(0);
                                              toast.success(response.message, {
                                                autoClose: 1500,
                                              });
                                            }
                                           
                                          })
                                          .catch((err) => {
                                            setDisableButton(0);
                                          });


                                      }
                                      //props.sendData(modalMobile);
                                    })
                                    .catch((err) => {
                                      setDisableButton(0);
                                      ////console.log(err);
                                    });
                                // })
                                // .catch((err) => {
                                //   setDisableButton(0);
                                //   console.log('catch ERROR', err);
                                // });
                            } catch (error) {
                              setDisableButton(0);
                              console.log('ERROR ', error);
                            }
                            //onClickHandler(e);
                          }}
                        >

                          {disableButton == 0 ? 'Verify' : <img src={loaderImage} className="custom-loader" style={{ height: "20px", marginRight: "5px" }} />}
                        </button>
                      </div>
                      {/* {mobileOtp === "" && <h4 className="text-center text-11 text-color11 mt-2">Please Enter OTP</h4>} */}
                      {showError && <p className="error_msg" style={{ color: "red", fontSize: "smaller", textAlign: "center", textTransform: "capitalize", marginTop: "10px" }}>{
                        showError === 'mobile Invalid OTP.' ? 'Invalid OTP' : showError
                      }</p>}
                      <h4 className="text-11 text-color8 mt-3 text-center">
                       An OTP has been sent to your new mobile number.
                      </h4>
                      {/* <h4 className="text-11 text-color8">
              Time remaining 256sec
            </h4> */}
                      <h4 className="text-11 text-color8 mt-3 text-center">
                        Didn’t receive OTP?{" "}
                        {counter === 0 ? (
                          <button
                            className="ms-1 resend-button"
                            type="button"
                            onClick={() => {
                              const otpValues = {
                                client_code: client_code,
                                mobile: formValues2.mobile,
                              };

                              resendOtp(otpValues)
                                .then((response) => {
                                  ////console.log(response);
                                  if (response.status === true) {
                                    setGenerateSuccessOtp1(true);
                                    setCounter(30);
                                    toast.success(
                                      "OTP has been sent to your new mobile number.",
                                      {
                                        autoClose: 1500,
                                      }
                                    );
                                  } else {
                                    toast.error("Couldn't send OTP. Try again!", {
                                      autoClose: 1500,
                                    });
                                  }
                                })
                                .catch((err) => {
                                  toast.error("Couldn't send OTP. Try again!", {
                                    autoClose: 1500,
                                  });
                                });
                            }}
                          >
                            Resend OTP
                          </button>
                        ) : (
                          <button
                            className="ms-1 resend-button1"
                            type="button"
                            disabled
                          >
                            Resend OTP
                          </button>
                        )}
                        {counter > 0 && `${counter} Seconds`}
                      </h4>
                      {/* </form> */}
                    </Col>
                  </Row>
                )}
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </React.Fragment>
  );
};

export default LinkNewMobile;
