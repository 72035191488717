import React, { useState, useEffect } from "react";
import { shoppingCart } from "../../common/data/ecommerce";
import dayjs from "dayjs";
//Import Breadcrumb
import { Link } from "react-router-dom";


//redux
import { useSelector } from "react-redux";

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  CardHeader,
  UncontrolledAlert,
} from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import {
  notificationcountremove
} from "../../helpers/fakebackend_helper";
import img from '../../assets/images/play botton.jpg'
import MediaPlayer from "../../Components/Common/MediaPlayer";

const NotificationCart = () => {
  document.title = "Bigul Admin & Dashboard";

  const [productList, setproductList] = useState(shoppingCart);
  const [charge, setCharge] = useState(0);
  const [tax, setTax] = useState(0);
  const [dis, setDis] = useState(0);
  const [modal, setModal] = useState(false);
  const [media, setMedia] = useState('');

  // const { notificationsList } = useSelector((state) => ({
  //   notificationsList: state.Notifications.NotificationsList,
  // }));

  const { notificationsList, NotificationsListCount } = useSelector((state) => ({
    notificationsList: state.Notifications.NotificationsList,
    NotificationsListCount: state.Notifications.NotificationsListCount,
  }));


  const assigned = productList.map((item) => item.total);
  let subTotal = 0;
  for (let i = 0; i < assigned.length; i++) {
    subTotal += Math.round(assigned[i]);
  }


  useEffect(() => {
    let dis = 0.15 * subTotal;
    let tax = 0.125 * subTotal;

    if (subTotal !== 0) {
      setCharge(65);
    } else {
      setCharge(0);
    }
    setTax(dis);
    setDis(tax);
  }, [subTotal]);

  function removeCartItem(id) {
    var filtered = productList.filter(function (item) {
      return item.id !== id;
    });

    setproductList(filtered);
  }

  function countUP(id, prev_data_attr, itemPrice) {
    setproductList(
      productList.map((p) =>
        p.id === id
          ? {
            ...p,
            data_attr: prev_data_attr + 1,
            total: (prev_data_attr + 1) * itemPrice,
          }
          : p
      )
    );
  }

  function countDown(id, prev_data_attr, itemPrice) {
    setproductList(
      productList.map((p) =>
        p.id === id && p.data_attr > 0
          ? {
            ...p,
            data_attr: prev_data_attr - 1,
            total: (prev_data_attr - 1) * itemPrice,
          }
          : p
      )
    );
  }

  useEffect(() => {
    console.log('notificationcountremovenotificationcountremove');
    const client_code = localStorage.getItem("client_id");
    const client_token = localStorage.getItem("client_token");
    notificationcountremove({ client_code: client_code, client_token: client_token })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const toggle = () => setModal(!modal);

  const modalOpen = (media) => {
    setModal(true);
    setMedia(media);
  };
  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={toggle} size="md">
        <div style={{ padding: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', justifyContent: 'space-between' }}>
            <p style={{ margin: 0, fontWeight: 'bold' }}>{media?.title}</p>
            <button
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
              onClick={toggle}
            >
              <i className="mdi mdi-close" style={{ fontSize: '24px' }}></i>
            </button>
          </div>
          <p style={{ margin: '0' }}>{media?.description}</p>
        </div>


        <ModalBody>
          <MediaPlayer mediaUrl={media?.media_file} />
        </ModalBody>
      </Modal>
      <Col xxl={9} xl={9} lg={9}>
        {notificationsList.map((notification, index) => (
          <Card key={index}>
            <CardBody className={`${notification.read_status === 1 && 'message-read'}`}>
              <div className="d-flex notification-body">
                <div className={`avatar-xs me-3 ${notification.read_status === 1 && 'message-read-icon'}`}>
                  <span className="avatar-title rounded-circle">
                    <i className="ri-message-line"></i>
                  </span>
                </div>
                <div className="flex-1 pe-5 notification-body-text">
                  {/* <Link to="#" className="stretched-link"> */}
                  <h6 className="mt-2 mb-2 text-4 text-color1">{notification.title}</h6>
                  {/* </Link> */}
                  {/* <Link to="#" className="stretched-link"> */}
                  <h6 className="mt-0 mb-2 text-11 text-color1">
                    {notification.description}
                  </h6>
                  {/* </Link> */}
                </div>
                <div className="flex-1 pe-5 notification-body-text">
                  <p className="mb-0 text-uppercase text-14 text-color2">
                    <span >
                      <i className="mdi mdi-clock-outline" ></i> {
                        notification.start_date == notification?.end_date ?
                      dayjs(notification.start_date).format("D MMM")
                      :
                      dayjs(notification.start_date).format("D MMM") + ' '+'To' +' '+ dayjs(notification.end_date).format("D MMM")
                    }

                    </span>
                  </p>
                  {notification?.media_file && (
                    <div className="circle-container">
                      <div className="circle" onClick={() => modalOpen(notification)}>
                        <img src={img} alt="Play" style={{ height: '50px', width: '50px' }} />

                      </div>
                    </div>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        ))}
      </Col>
    </React.Fragment>
  );
};

export default NotificationCart;
