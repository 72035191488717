import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
//redux
import { useSelector, useDispatch } from "react-redux";
import { Card, CardHeader, Col } from "reactstrap";
//action
import {
  getMarginOverview,
  getBankDetails,
} from "../../store/marginOverview/action";
import { getCollateral } from "../../store/Collateral/action";
import {
  getLedgerApi,
  latestUpdateOnApi,
  SymphonyWithdrawalBalance
} from "../../helpers/fakebackend_helper";
// image
import margin_overview_bottom from "../../assets/images/logo/margin_overview_bottom.png";
import "./masonry.css";
import WithdrawFunds from "../../Components/CustomComponents/Models/withDraw/WithdrawFunds";
import Encripted from "./Encripted";
import loaderImage from "../../assets/images/Loader.png";
import { LOGOUT_APP } from "../../helpers/url_helper";




const MarginOverview = (props) => {
  var advancedFormat = require("dayjs/plugin/advancedFormat");
  dayjs.extend(advancedFormat);
  const [isDownload, setIsDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [updateData, setUpdateData] = useState("");
  const { generatedKey, encryptKey } = props;
  const [TotalValue, setTotalValue] = useState(0);
  const dispatch = useDispatch();
  const startDate = new Date();
  const endDate = new Date();
  startDate.setDate(startDate.getDate() - 7);

  const [selectDate, setSelectDate] = useState({ date: [startDate, endDate] });
  const openState = {
    CUpdate: true,
    otp1: false,
    done: false,
  };
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const symphony_token = localStorage.getItem("symphony_token");
  const expires = localStorage.getItem("expires");
  

  useEffect(() => {
    const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds
    if (expires < currentTime) {
      return window.open(LOGOUT_APP, "_self");
    }
  }, [expires]);
  
  useEffect(() => {
    const fetchData = async () => {
      await latestUpdateOnApi({ clientCode: client_code })
        .then((response) => {
          // //console.log(response);
          setUpdateData(response.createdAt);
        })
        .catch((err) => {
          ////console.log(err);
        });
    };


    const timer = setTimeout(() => {
      fetchData();
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  console.log("client_code", client_code);
  useEffect(() => {
    setLoading(true)
    SymphonyWithdrawalBalance({
      client_token: client_token,
      token: symphony_token,
    })
      .then((response) => {
        if(response.msg == 'Invalid Token')
          {
            setLoading(false)
            setWithdrawalBalance(0);

          }
        if (!response.balance_arr.withdrawal) {
          setLoading(false)
          setTotalValue(0);
        } else {
          var total_margin_set = response.balance_arr.total_margin;
          setTotalValue(total_margin_set);
          setLoading(false)

        }
      })
      .catch((err) => {
        setWithdrawalBalance(0);
        console.log(err);
      });
  }, [generatedKey, encryptKey]);
  ////console.log(updateData);
  useEffect(() => {
    dispatch(getMarginOverview({ apiKey: generatedKey, code: encryptKey }));
    dispatch(getBankDetails({ apiKey: generatedKey, code: encryptKey }));
    dispatch(getCollateral({ apiKey: generatedKey, code: encryptKey }));
  }, [dispatch, generatedKey, encryptKey]);

  const { availableMargin } = useSelector((state) => ({
    availableMargin: state.MarginOverview,
  }));
  //console.log(availableMargin)
  const { Collateral } = useSelector((state) => ({
    Collateral: state.Collateral.Collateral,
  }));
  //console.log(Collateral);
  const addFundHandler = () => {
    const paylod = {
      'ClientCode': client_code,
      redirectUrl: 'https://dashboard.bigul.co/dashboard',
    }

    window.open(
      `https://payment.bigul.co/v1/?enc=${Encripted([paylod])}`,
      "_blank"
    );
  };

  // const amountFormatter = (data) => {
  //   if (data > 0) {
  //     ////console.log(data);
  //     return `- ₹${data.toLocaleString("en-US").toString()}`;
  //   } else if (data < 0) {
  //     ////console.log(data);
  //     return `₹${data.toLocaleString("en-US").toString().slice(1)}`;
  //   } else {
  //     return `₹${data.toLocaleString("en-US").toString()}`;
  //   }
  //   //  ////console.log(data);
  // };

  // const amountFormatter = (data, data2) => {
  //   let Total = 0;
  //   if (data > 0) {
  //     Total = -Math.abs(data) + data2;
  //     let t = Math.round(Total * 100) / 100;
  //     if (t > 0) {
  //       return `₹${t.toLocaleString("en-IN").toString()}`;
  //     } else {
  //       return `- ₹${t.toLocaleString("en-IN").toString().slice(1)}`;
  //     }
  //     console.log(Total);
  //   } else if (data < 0) {
  //     ////console.log(data);
  //     Total = Math.abs(data) + data2;
  //     let t = Math.round(Total * 100) / 100;
  //     if (t > 0) {
  //       return `₹${t.toLocaleString("en-IN").toString()}`;
  //     } else {
  //       return `- ₹${t.toLocaleString("en-IN").toString().slice(1)}`;
  //     }
  //   } else {
  //     Total = data + data2;
  //     let t = Math.round(Total * 100) / 100;
  //     if (t > 0) {
  //       return `₹${t.toLocaleString("en-IN").toString()}`;
  //     } else if (t == 0) {
  //       return `₹0`;
  //     } else {
  //       return `- ₹${t.toLocaleString("en-IN").toString().slice(1)}`;
  //     }
  //     //console.log(Total);
  //   }
  // };

  //.toLocaleString("en-US")
  const downloadHandler = () => {
    getLedgerApi({
      apiKey: generatedKey,
      code: encryptKey,
      startDate: dayjs(selectDate.date[0]).format("YYYY-MM-DD"),
      endDate: dayjs(selectDate.date[1]).format("YYYY-MM-DD"),
    })
      .then((response) => {
        setFile(response.finResult);
        setIsDownload(true);
        ////console.log(response);
      })
      .catch((err) => {
        ////console.log(err);
      });
  };

  const myArray = [];
  for (let i = 0; i < file.length; i += 1) {
    const childArray = file[i];
    const obj = {
      Name: childArray.LNarrat.replace("#", ""),
      Date: childArray.Ldate,
      Amount: childArray.Damount,
      Balance: childArray.Camount,
    };
    myArray.push(obj);
  }

  const userHeader = ["Name", "Date", "Amount", "Balance"];
  myArray.unshift(userHeader);

  const array2 = [];
  myArray.forEach((el) => {
    const propertyValues = Object.values(el);
    array2.push(propertyValues);
  });
  // //console.log(array2);
  const csvContent = `data:text/csv;charset=utf-8,
		 ${array2.map((e) => e.join(",")).join("\n")}`;
  // //console.log(array2);
  // //console.log(csvContent);
  // const encodedUri = encodeURI(csvContent);
  // //console.log(encodedUri)
  const link = document.createElement("a");
  link.setAttribute("href", csvContent);
  link.setAttribute("download", `Ledger`);
  document.body.appendChild(link);
  useEffect(() => {
    if (isDownload === true) {
      link.click();
    }
    setIsDownload(false);
  }, [isDownload]);

  const diff_minutes = (dt2, dt1) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  };

  const CollateralValues = [];
  const CollateralNew = Collateral.filter((el) => {
    if (el.CollateralQuantity > 0) {
      CollateralValues.push(
        el.CollateralQuantity * el.mktrate -
        (el.CollateralQuantity * el.mktrate * el.Haircut) / 100
      );
    }
    return el.CollateralQuantity > 0;
  });

  // //console.log(CollateralNew)
  // //console.log(CollateralValues)
  const CollateralValuessubTotal = CollateralValues.reduce(function (acc, val) {
    return acc + val;
  }, 0);
  //console.log(CollateralValuessubTotal);

  //const TotalValue = availableMargin.availableMargin.length > 0 && +availableMargin.availableMargin[0].Final1 + CollateralValuessubTotal;
  ////console.log(TotalValue);
  const a = 8;
  const b = 4;

  // let TotalValue = 0;
  // if (
  //   availableMargin.availableMargin.length > 0 &&
  //   +availableMargin.availableMargin[0].Final1 > 0
  // ) {
  //   TotalValue =
  //     -Math.abs(+availableMargin.availableMargin[0].Final1) +
  //     CollateralValuessubTotal;
  // } else if (
  //   availableMargin.availableMargin.length > 0 &&
  //   +availableMargin.availableMargin[0].Final1 < 0
  // ) {
  //   TotalValue =
  //     Math.abs(+availableMargin.availableMargin[0].Final1) +
  //     CollateralValuessubTotal;
  // } else if (
  //   availableMargin.availableMargin.length > 0 &&
  //   +availableMargin.availableMargin[0].Final1 === 0
  // ) {
  //   TotalValue =
  //     +availableMargin.availableMargin[0].Final1 + CollateralValuessubTotal;
  // }

  //console.log(TotalValue);

  return (
    <React.Fragment>
      <Col
        xxl={3}
        xl={3}
        lg={6}
        md={6}
        sm={6}
        className="spacing-remove-demounting dashboard-widget-bottom-margin2"
      >
        <Card className="m-0">
          <CardHeader className="border-0 d-flex">
            <div className="flex-grow-1">
              <h4 className="text-6 text-color1 mb-1">Available Margin</h4>
              <h5 className="text-4 text-color1 mb-0">
                (Cash Balance + Collateral - Margin used)
              </h5>
            </div>
            <div className="flex-shrink-0">
              <div className="card-overview-div-logo rounded-circle align-middle">
                <Link
                  className="card-overview-div-logo-button"
                  //onClick={downloadHandler}
                  to="/overview"
                >
                  <i
                    className={"align-middle " + "ri-arrow-right-fill"}
                    style={{ color: "#FFF" }}
                  ></i>
                </Link>
              </div>
            </div>
          </CardHeader>
          <div className="card-body px-3 py-0">
            <h4
              className={`text-8 text-color5  mb-1 flex-grow-1 ${TotalValue > 0 ? "text-color6" : "text-color11"
                }`}
            >{
                loading ?
                <div style={{display:'flex',justifyContent:'center'}}>
                  <img src={loaderImage} className="custom-loader" style={{ height: "20px",}} />
                   </div>
                  : '₹' + TotalValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }

            </h4>
            <h4 className="text-1 mb-0 flex-grow-1 text-color1">
              {updateData !== "" &&
                `As on  ${dayjs(updateData).format("DD MMM YYYY")}`}
              {/* last updated {diff_minutes(new Date, new Date(updateData))} */}
            </h4>
          </div>
          <div className="card-body p-3">
            <div className="pt-1">
              <button
                type="button"
                className="btn btn-success text-4 w-100 mb-3 margin-overview-button2"
                style={{ borderRadius: "5px" }}
                onClick={addFundHandler}
              >
                <i
                  className={"align-middle me-2 fs-5 " + "ri-add-circle-line"}
                ></i>
                Add Funds
              </button>
              {/* {availableMargin.availableMargin.length > 0 &&
              +availableMargin.availableMargin[0].Final1 < 0 ? (
                <WithdrawFunds data={availableMargin} openState={openState} />
              ) : (
                <button
                  type="button"
                  className="btn btn-primary text-4 w-100 margin-overview-button1"
                  style={{ borderRadius: "5px", cursor: "notAllowed" }}
                  disabled
                >
                  <i
                    className={"align-middle me-2 fs-5 " + "ri-wallet-line"}
                  ></i>
                  Withdraw
                </button>
              )} */}
              <Link
                type="button"
                className="btn btn-success text-4 w-100 margin-overview-button2  add-Collateral-btn "
                style={{
                  borderRadius: "5px",
                  cursor: "notAllowed",
                  backgroundColor: "#5367fc",
                  borderColor: "#5367fc",
                }}
                to="/pledge"
              >
                <i
                  className={"align-middle me-2 fs-5 " + "ri-add-circle-line"}
                ></i>
                Add Collateral
              </Link>
              {/* <button
                type="button"
                className="btn btn-success text-4 w-100 margin-overview-button2"
                style={{ borderRadius: "5px" }}
              >
                <i className={"align-middle me-2 fs-5 " + "ri-wallet-line"}></i>
                Withdraw
              </button> */}
            </div>
          </div>
          <div className="card-overview-div-image">
            <img className="card-overview-image" src={margin_overview_bottom} />
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default MarginOverview;

