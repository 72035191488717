import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Input,
  Modal,
  Card,
  CardBody,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap";
import Loader from "../../../Common/Loader";
//redux
import { useSelector, useDispatch } from "react-redux";
//action
import { SymphonyWithdrawalBalance, checkModificationStatus, esignPayment, modificationEsign, selectedFeatureApi } from "../../../../helpers/fakebackend_helper";
import { addToCart } from "../../../../store/cart/action";
import active from "../../../../assets/images/logo/active.png";
import {
  DDPIStatusUpdateApiCall,
  AddNomineeApiCall,
  DDPIShameESign,
  AfterESignDeduction,
  BankMod,
} from "../../../../helpers/fakebackend_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { MOD_EKYC_NEW, redirectApp } from "../../../../helpers/url_helper";
import Encrypted from "../../../../pages/Dashboard/Encripted";
// TODO Ui and design and functions.
var amount = ''
const BankESignMod = (props) => {
  const { ClientID } = useSelector((state) => ({
    ClientID: state.ClientID.ClientID
  }));
  const { data, mainData, fullBankPrimary, Balance } = props;


  const mainData1 = mainData?.filter((item) => item?.Default_Bank == 1)
  const symphony_token = localStorage.getItem("symphony_token");
  const client_token = localStorage.getItem("client_token");

  const matchingModification = fullBankPrimary.find(modification =>
    modification.modify_key === 'bank_account_number'
  );

  console.log(matchingModification?.new_value == data.ACNUMBER.trim(), 'llllll');


  const client_code = localStorage.getItem("client_id");

  const [balance, setBalance] = useState(false);
  const [deseble, setDeseble] = useState(false);

  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const [esignModal, setEsignmodal] = useState(false);

  const tog_signUpModals = () => {
    setmodal_signUpModals(!modal_signUpModals);
  };
  const tog_signUpModals1 = () => {
    setEsignmodal(!esignModal);
  };

  const handlesubmit = () => {
    setBalance(false)
    const payload = {
      client_code: client_code,
      entry_type: 'primary_bank',

      new: JSON.stringify({
        bank_account_number: data?.ACNUMBER,
        bank_name: data?.BANK_NAME,
        ifsc_code: data?.CIFSCCODE,
        micr_number: data?.CMICRBANKCODE,
      }),
      old: JSON.stringify({
        bank_account_number: mainData1[0]?.ACNUMBER,
        bank_name: mainData1[0]?.BANK_NAME,
        ifsc_code: mainData1[0]?.CIFSCCODE,
        micr_number: mainData1[0]?.CMICRBANKCODE,
      }),

      modify_key: JSON.stringify([
        'bank_account_number',
        'bank_name',
        'ifsc_code',
        'micr_number'
      ]),

    }

    axios.post(MOD_EKYC_NEW + '/update-modification', { 'enc': Encrypted([payload]) }, {
      headers: {

      },
    })

      .then(response => {
        if (response.status) {
          setmodal_signUpModals(false)
          props.onStateChange(1);
          setEsignmodal(true)


        } else {
          toast('something went wrong')
        }

      }).catch(() => {
        toast('something went wrong')
      })
  }

  const handleEsignConfirm = () => {
    setBalance(false)
    if (isNaN(Balance)) {
      toast.error('Unable to fetch your ledger balance. Please try after some time.');
      return;
  }
    if (Balance < 25) {
      setBalance(true)
    }
    else {
      setDeseble(true)
      modificationEsign({
        client_code: client_code,
        entry_type: 'primary_bank',
      })
        .then((response) => {
          if (response?.status) {
            setDeseble(false)
            window.open(`${response?.redirectUrl}`, "_blank");
            // props.apicheck true
            props.apicheck(true)
            // setShouldContinueApi(true)
            setEsignmodal(false)

          }
          else {
            toast(response.message)
            setDeseble(false)
          }
        })
        .catch((err) => {
          setDeseble(false)

          toast('something went wrong')
        });
    }


  }


  const handleCancel = async () => {
    try {
      const payload = {
        client_code: client_code,
        entry_type: 'primary_bank'
      }; // Your POST data here
      const response = await axios.post(MOD_EKYC_NEW + '/delete-modification', { 'enc': Encrypted([payload]) });


      if (response?.status == true) {

        setEsignmodal(false)
        setBalance(false)
        props.onStateChange(1);

      } else {

        toast.error('something went wrong please try again')
      }
    } catch (error) {

    }
  }
  // const handlepay = () => {
  //   esignPayment({
  //     enc_data: amount > 0 ? `ClientCode=${encodeURIComponent(client_code)}&amount=${encodeURIComponent(25-amount)}&redirectUrl=${encodeURIComponent('https://dashboard.bigul.app/profile')}&SubscriptionPlan=${encodeURIComponent('client_modification')}` : `ClientCode=${encodeURIComponent(client_code)}&amount=${encodeURIComponent((Math.abs(amount) + 25))}&redirectUrl=${encodeURIComponent('https://dashboard.bigul.app/profile')}&SubscriptionPlan=${encodeURIComponent('client_modification')}`
  //   })
  //     .then((response) => {
  //       if (response?.message == "Data Encrypted") {
  //         window.location.href = 'https://payment.bigul.app/?enc=' + response?.enc_data;

  //       }
  //       else {
  //         toast('data not found')
  //       }
  //     }).
  //     catch((error) => {
  //       toast('something went wrong')
  //     })
  // }
  const handlepay = () => {
    // Create the payload object with necessary parameters
    const payload = {
      ClientCode: client_code,
      amount: Balance > 0 ? (25 - Balance) : (Math.abs(Balance) + 25),
      redirectUrl: redirectApp,
    };

    // Encrypt the payload using the Encripted function
    const encryptedPayload = Encrypted([payload]);

    // Redirect to the payment URL with the encrypted payload
    window.location.href = 'https://payment.bigul.co/v1/?enc=' + encryptedPayload;
  };

  return (
    <React.Fragment>
      {
        fullBankPrimary.length == 0 ?
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="SwitchCheck1"
            checked={modal_signUpModals}
            onChange={() => tog_signUpModals()}

          />
          :
          (fullBankPrimary.length > 0 && fullBankPrimary[0]?.is_send_to_ld == 1) &&
              matchingModification?.new_value == data.ACNUMBER.trim() ?
              <p style={{ backgroundColor: 'green' }}>In progress</p>
              :
              (fullBankPrimary.length > 0 && fullBankPrimary[0]?.is_esign == 1) &&
                matchingModification?.new_value == data.ACNUMBER.trim() ?
                <p className="text-5 text-color10 m-0 text-center"

                >Pending verification</p> :

          (fullBankPrimary.length > 0 && fullBankPrimary[0]?.is_esign == 0) &&
            matchingModification?.new_value == data.ACNUMBER.trim() ?
            <button
              type="button"
              style={{ marginLeft: '10px' }}
              className="btn btn-primary modal-content-Button my-3"
              onClick={() => { setEsignmodal(true), setBalance(false) }}


            >
              {" "}
              {'Esign'}

            </button>
            :

            
                null



      }



      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={modal_signUpModals}
        backdropClassName="background-color4"
        toggle={() => {
          tog_signUpModals();
        }}
        centered
      >
        <ModalHeader
          className="px-4 pt-4 justify-content-center"
          toggle={() => {
            tog_signUpModals();
          }}
        >

          <h4 className="text-2 text-color7 spacing-remove-bottom text-center">
            Confirm
          </h4>
        </ModalHeader>
        <ModalBody className="p-4">
          <div>
            <h4 className="text-9 text-color5 mt-2 text-center">
              Bank Name: {data.BANK_NAME}
            </h4>
            <h4 className="text-9 text-color5 mt-2 text-center">
              Bank Account Number : {data.ACNUMBER}
            </h4>
            <h4 className="text-9 text-color5 mt-2 text-center">
              IFSC Code : {data.CIFSCCODE}
            </h4>
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-primary modal-content-Button my-3"
                onClick={() => {
                  handlesubmit()
                }}
              >
                Set As Primary Account
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={esignModal}
        backdropClassName="background-color4"
        toggle={() => {
          tog_signUpModals1();
        }}
        centered
      >
        <ModalHeader toggle={() => {
          tog_signUpModals1();
        }} >{balance ? 'Proceed to Pay' : 'Proceed to Esign'}</ModalHeader>
        <ModalBody>
          {
            balance ?
              <h4 className="text-9 text-color5 mt-3 text-center">
                The amount available in your ledger balance is not not sufficient for this transaction.
              </h4>
              :
              <h4 className="text-9 text-color5 mt-3 text-center">
                Please proceed to E-sign to confirm your request for modification. Rs. 25 (including GST) charge will be applicable for modification. The amount will be deducted from your ledger balance.
              </h4>
          }
        </ModalBody>
        <div className="d-flex justify-content-center">
          {
            balance ?
              <>
                <button
                  type="button"
                  className="btn btn-primary modal-content-Button my-3"
                  onClick={handlepay}
                >
                  {" "}
                  Proceed to pay
                </button>
                <button
                  type="button"
                  style={{
                    marginLeft: '10px',
                    backgroundColor: 'gray',
                    color: 'white',
                    border: '1px solid gray',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}
                  className="btn btn-primary modal-content-Button my-3"
                  onClick={handleCancel}
                >
                  Cancel Request
                </button>
              </>
              :
              <>
                <button
                  type="button"
                  style={{ marginLeft: '10px' }}
                  className="btn btn-primary modal-content-Button my-3"
                  onClick={handleEsignConfirm}
                  disabled={deseble}

                >
                  {" "}
                  {deseble ? 'please wait...' : 'Proceed to eSign'}

                </button>
                <button
                  type="button"
                  style={{
                    marginLeft: '10px',
                    backgroundColor: 'gray',
                    color: 'white',
                    border: '1px solid gray',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}
                  className="btn btn-primary modal-content-Button my-3"
                  onClick={handleCancel}
                >
                  Cancel Request
                </button>
              </>
          }
        </div>

      </Modal>
    </React.Fragment>
  );
};

export default BankESignMod;
